import React from 'react';
import logo from './images/myLogo.png';

function Footer(props) {
    return (
        <div>
             <section className="footer">
               <div style={{marginBottom:'41px'}}>
               <img style={{width:'77px'}}
                 src={logo} alt="logo"/>
                </div>
        <p>
        All copyright&#169; reserved.
        </p>

       {/* <div>
          <Link
            to="https://www.flaticon.com/free-icons/developer"
            title="developer icons"
          >
            Some of icons created by Flat Icons - Flaticon
          </Link >
        </div> */}
      </section>
        </div>
    );
}

export default Footer;
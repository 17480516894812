import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Learning from "./images/programmer.png";
import Interview from "./images/interview.png";
import Offer from "./images/job-offer.png";
import Acheived from "./images/trophy.png";
import Course from "./Course";
import DownArrow from './images/arrow-down.png';

function Home(props) {

  const [loadBgImage, setLoadBgImage] = useState(false);

  useEffect(() => {
    let observer = new window.IntersectionObserver(function (entries, self) {

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImges(entry.target);
          self.unobserve(entry.target);
        }
      });
    }, );
    const elment = document.querySelectorAll("[data-src]");
    elment.forEach((el) => {
      observer.observe(el);
    });

  }, []);

  function loadImges(el) {
    if(el){
      setLoadBgImage(true);
    }else{
      setLoadBgImage(false);
    }
  }

  return (
    <div>
        <Course/>
      {/* <!-- -------procedure--------> */}
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'7px'}}>
      <Link to='/courses' className="hero-btn red-btn">VIEW COMPLETE COURSE</Link>
      </div>
      <section className="facilities">
        <h1>Procedure</h1>
        <p>We will not leave your hand till your placement</p>

        <div>
          <div
          className="responsive-card"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "53px",
              alignItems: "center",
            //   marginRight:"50px",
            //   marginLeft:'50px'
            }}
          >
            <div className="procedure-col">
              <img src={Learning} alt="learing" />
              <span>Learning</span>
            </div>
            
            <div 
            className="prp"
            style={{ width: "50%",}}>
              <p style={{padding:'41px'}}>
                Each and every students of us are important that's why we start
                the course from zero level and will make you zero to hero.
                According to your selection of course we will teach you every steps
                of coding, does not matter which educational backround you belong, the
                only thing is needed, he/she should belong to science
                backround.
              </p>
            </div>
          </div>

          <div
          className=" mock"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "53px",
              alignItems: "center",
            }}
          >
            <div className="prp" style={{ width: "40%" }} >
              <p style={{padding:'41px'}}>
               With mock interviews you will get confidence on the non-verbal and verbal communication abilities. It will provide you an opportunity to make mistakes and work on correcting them in a safe atmosphere.
              </p>
            </div>
            <div className="procedure-col">
              <img src={Interview} alt="learing" />
              <span>Mock Interviews</span>
            </div>
          </div>

          <div
          className="responsive-card"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "53px",
              alignItems: "center",
            }}
          >
            <div className="procedure-col">
              <img src={Offer} alt="learing" />
              <span>Job Opportunities</span>
            </div>
            <div style={{ width: "40%" }} className="prp">
              <p style={{padding:'41px'}}>
              You don't need to go outside and search for a job, you just need to focus on your study, leave it on us. We will provide you a list of job Opportunities , so that you get placed on time.
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "53px",
              alignItems: "center",
              flexDirection:'column-reverse',
            }}
          >
            <div style={{ width: "40%" , textAlign: "center", marginTop:'13px'}} className="prp">
            </div>
              <span style={{opacity:'0.5'}}>Get Placed</span>
            <div className="procedure-col">
              <img src={Acheived} alt="learing"/>
            </div>
          </div>
        </div>
      </section>

      <section style={{padding:'33px', marginTop:'33px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <h1 >Struggling with financial crisis ?</h1> </div>
            <div
            style={{marginTop:'33px',maxWidth:"900px"}}
            >
                <p> Are you passionate for coding but struggling with financial crisis ? No worries we are with you, neither you have to pay us anything during the entire course nor after the complition of the course. You will be completely funded by us, but you need to pay us security deposit which will be returned after the completion of the course. This is because you don't leave our course in middle of the batch. For this eligibilty you need to discuss your present family backround with us in detail and have to provide some proofs, which you have to submit after the discussion.</p>
                <p style={{marginTop:'33px', color:'teal'}}>Yes we care for you and think for you.</p>
                <p style={{marginTop:'33px', color:'red'}}>Note: the above section is only for those who are actually struggling with financial crises. Incase of any wrong information given by the candidate strict legal action will be taken against them and he/she will be terminated from the batch immediately. </p>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img src={DownArrow} style={{width:'57px', marginTop:'11px'}} alt="down Arrow"/></div>
            <div style={{ marginTop:'13px'}}>
      <Link to='/free-classes' className="hero-btn red-btn">APPLY FOR FREE</Link>
    
      </div>
      </section>

      {/* <!-- ----Call To Action---- --> */}

      <section  className={loadBgImage ? "cta" : ""} data-src="">
        <h1>
          Enroll For Our Various Online Courses <br />
          Anywhere From The Asia.
        </h1>
        <Link to="/apply" className=" hero-btn">
          APPLY NOW
        </Link>
      </section>
    </div>
  );
}

export default Home;

import React from 'react';

function Loader(props) {
    return (
        <div style={{display:'flex', justifyContent:"center", alignItems:'center', height:'60vh'}}>
            <h2>Loading.....</h2>
        </div>
    );
}

export default Loader;
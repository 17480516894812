
import {BrowserRouter, Routes, Route} from "react-router-dom";

import Navbar from "./components/Navbar";
import React from "react";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollTop";
import Loader from "./components/Loader";
import {Helmet} from "react-helmet";
import Home  from "./components/Home";

const LazyAbout = React.lazy(()=> import("./components/About"));
const LazyContact = React.lazy(()=> import("./components/Contact"));
const LazyHome = React.lazy(()=> import("./components/Home"));
const CourseDetail = React.lazy(()=> import("./components/CourseDetail"));
const FreeClasses = React.lazy(()=> import("./components/FreeClasses"));
const ApplyForm = React.lazy(()=> import("./components/ApplyForm"));
const NotFound = React.lazy(()=> import("./components/NotFound"));

function App() {
  return (
    <div >
           
      <BrowserRouter>
      <ScrollToTop/>
      <Helmet>
       <title>DM Coding School - Web Development Live Classes Online</title>
      <meta name="description" content=" DM Coding School provide, live classes during the entire
      course. You don't need to depend on recorded videos. We are the one, who teach completely free to the financially weak
      students."/>
              </Helmet>
      <Navbar/>
      {/* <Header/> */}
      <React.Suspense fallback={<Loader/>}>
      <Routes>
        
      <Route exact path="/" element={
        <Home/>
       
      }/>
      <Route exact path="/contact" element={
          <LazyContact/>
      }/>
      <Route exact path="/about" element={
          <LazyAbout/>
    }
      />
      <Route exact path="/courses" element={
           <CourseDetail/>
      }/>
      <Route exact path="/free-classes" element={
           <FreeClasses/>
      }/>
      <Route exact path="/apply" element={
           <ApplyForm/>
      }/>
      <Route exact path="*" element={
           <NotFound/>
      }/>
      </Routes>
     </React.Suspense>
     <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, {useState} from 'react';
import Menu from "./images/menu-bar.png";
import Close from "./images/clear.png";
import {Link} from 'react-router-dom';
import logo from './images/myLogo.png';
function Navbar(props) {
    const [menu, setMenu] = useState(false)

    const styleMenu = {
        left: menu ? 0 : "-100%"
    }
    const toggleMenu = () => {
        setMenu(!menu)
    }
    return (
        <div>
           <header >
            <div className='menu' onClick = {() => setMenu(!menu)} style={{marginLeft:'21px'}}>
                <img src={Menu} alt="" width="43"/>
            </div>

            <div className='logo' >
            <img style={{width:'75px', marginLeft:'23px'}}
                 src={logo} alt="logo"/>
            </div>

            <ul style={styleMenu}>
                <li><Link to='/' onClick = {() => setMenu(!menu)}>Home</Link></li>
                <li><Link to = '/about' onClick = {() => setMenu(!menu)}>About</Link></li>
            <li><Link to = '/courses' onClick = {() => setMenu(!menu)}>Courses</Link></li>
            <li><Link to = '/apply' onClick = {() => setMenu(!menu)}>Join Now</Link></li>
            <li><Link to = '/free-classes' onClick = {() => setMenu(!menu)}>Free Join</Link></li>
            <li><Link to = '/contact' onClick = {() => setMenu(!menu)}>Contact</Link></li>


                <li onClick = {() => setMenu(!menu)}>
                    <img src={Close} alt='' width="30" className='menu' />
                </li>
            </ul>
           
        </header>
        </div>
    );
}

export default Navbar;
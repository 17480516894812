import React from 'react';
import mongodb from "./images/mongodb.png";
import express from "./images/express.png";
import react from "./images/react.png";
import node from "./images/node-js.png";

function Course(props) {
    return (
        <div>

    {/* <!-- -----------Course----------- --> */}

    <section className="course">
        <h1>Live Classes We Offer</h1>

        <div className="row">
          <div className="course-col">
            <h3>Full Stack Development</h3>
            <span> Duration: 8 Months </span>
            <div style={{ marginTop: "15px" }}>
              <img style={{ width: "4rem" }} src={mongodb} alt="Mern Stack" />
              <img style={{ width: "4rem" }} src={express} alt="Mern Stack" />
              <img style={{ width: "4rem" }} src={react} alt="Mern Stack" />
              <img style={{ width: "4rem" }} src={node} alt="Mern Stack" />
            </div>
          </div>
          <div className="course-col">
            <h3>Frontend Development</h3>
            <span> Duration: 6 Months </span>
            <div style={{ marginTop: "15px" }}>
              <img style={{ width: "4rem" }} src={react} alt="React js" />
            </div>
          </div>
          <div className="course-col">
            <h3>Backend Developer</h3>
            <span> Duration: 6 Months </span>
            <div style={{ marginTop: "15px" }}>
              <img style={{ width: "4rem" }} src={node} alt="Node js" />
            </div>
          </div>
        </div>
      </section>
      <div>
      </div>
        </div>
    );
}

export default Course;